import React, { useEffect, useState, useCallback, useRef, useMemo } from "react";
import useTrackStore from "./store/track/index";
import {useTrackComment,useDeleteTrack} from "./hooks/useAuthenticatedTrack";
import styled, {keyframes, css} from 'styled-components';
import usePlayerStore from "./store/player/usePlayer";
import {Button, TextField, Submenu, SubmenuGroup} from "./FormControls.js";
import Scrubber from "./Scrubber.js";
import CommentMarkers from "./CommentMarkers.js";
import Comments, {TextContainer} from "./Comments.js";
import {TextArea, SubmenuButton} from "./FormControls";
import { useForm } from "react-hook-form";
import {PageTheme, Heading1, mediaMax} from './Theme';
import Icon from './Icons';
import { useHistory } from 'react-router-dom';
import useSessionStore from "./store/session";
import useRecaptcha from "./hooks/useRecaptcha";
import RecaptchaDisclaimer from "./RecaptchaDisclaimer";
import LoadingSpinner from "./LoadingSpinner";
import { setDocumentTitle } from "./App";

const Submit = styled.button`
  width: 50px;
  flex: 0 0 50px;
  text-align: center;
  background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
  border: 0px;
  fill: ${ PageTheme.colors.create };
  border-top-right-radius: ${ PageTheme.properties.ButtonBorderRadius };
  border-bottom-right-radius: ${ PageTheme.properties.ButtonBorderRadius };
`

const SubmitIcon = styled(Icon)`
  margin: auto;
`

const Form = styled.form`
  background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
  border-radius: ${ PageTheme.properties.ButtonBorderRadius };
  border: 1px solid ${ PageTheme.colors.create };
  margin-bottom: ${ PageTheme.properties.size4 };
  font-size: 18px;
  cursor: default;
  display: flex;
  flex-grow: 1;
  min-height: 192px;

`
const StyledButton = styled(Button)`
  margin: ${ PageTheme.properties.size2 };

  background-color: ${ props => props.isActive ? PageTheme.colors[props.color] : null} !important;
  background: linear-gradient(
    0deg,
    ${ props => props.isActive ? PageTheme.colors[props.color] : null} 15%,
    ${ PageTheme.colors.ButtonBackground } 15%
  );

  display: ${props => props.visible?'flex':'none'}
`

const SyncButtonContainer = styled.div`
  display: inline-block;
  margin-bottom: 8px;
  button {
    height: 48px;
  }
`

const ButtonContainer = styled.div`
  cursor: pointer;
  display: inline-block;
`

const Container = styled.div`
    padding-top: 32px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    ${props => props.hasScrollContainer && 'height: 100%;'}
    box-sizing: border-box;
`

const AsideButtonContainer = styled.div`
    position: relative;
`

const StyledAsideButton = styled.button`
    background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
    border: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    fill: #FFF;
    outline: none;
    cursor: pointer;
`

const AsideButtonIcon = styled(Icon)`
  width: 25px;
`
const ReportContainer = styled.div`
  position: absolute;
  right: ${ PageTheme.properties.size4 };
  bottom: ${ PageTheme.properties.size4 };
  max-width: 500px;
`
const StyledReportForm = styled.div`
  background: #FFFFFF22;
  padding: ${ PageTheme.properties.size5 };
  border-radius: ${ PageTheme.properties.size2 };
`
const StyledCloseButton = styled(Button)`
  position: absolute;
  right: 0px;
  top: -14px;
`
const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-top: ${ PageTheme.properties.size4 };
  flex-grow: 1;
  min-height: 192px;
`

const Meta = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
	${mediaMax('small', css`
    display: none;
  `)}
`
const CommentContainer = styled.div`
  flex-grow: 1;
`

const Description = styled.div`
  margin-bottom: ${ PageTheme.properties.size5 };
`

const CommentPanel = styled.div`
  display: ${props => props.visible ? 'flex' : 'none'};
  flex-direction: column;
  min-height: 192px;
  flex-shrink: 0;
`

function AsideButton({onClick,children}){
  return <AsideButtonContainer>
    <StyledAsideButton onClick={onClick}>{children}</StyledAsideButton>
    </AsideButtonContainer>
}

function CommentForm({visible}){
	const { register, handleSubmit, reset, errors } = useForm();
  const { playing,commentActive,runtime,setCommentActive,commentSynced,setCommentSynced,setCommentStart,setCommentEnd } = usePlayerStore();
  const { saveDisplayName, displayName } = useSessionStore();
  const user = useSessionStore(state => state.user);
  const { track } = useTrackStore();
  const displayNameInput = useRef();
  const textInput = useRef();
  const comment = useTrackComment();
  const challenge = useRecaptcha();

	const onSubmit = async (data) => {
    const recaptchaToken = user ? null : await challenge();
    data = {...data, startTime: runtime.commentStart, endTime: runtime.commentEnd, track: track.uid, recaptchaToken}
    await comment(data);
    if(data.displayName){
      saveDisplayName(data.displayName)
    }
    reset();
    setCommentActive(false);
    setCommentSynced(true);
    setCommentStart(0);
    setCommentEnd(0);
  };
  
  useEffect(() => {
    if(visible){
      if(displayNameInput.current && !displayNameInput.current.value) displayNameInput.current.focus();
      else textInput.current.focus();
    }
  }, [visible])
  
  const commentSyncButtonsEnabled = playing;


  return <CommentPanel visible={visible}>
    <SyncButtonContainer>
      <Button
        onClick = {() => setCommentSynced(true)}
        visible = {!commentSynced}
        text = 'Sync to playback'
        iconID = "ChainDisconnected"
        color = {commentSyncButtonsEnabled ? "create" : "inactive"}
        disabled = {!commentSyncButtonsEnabled}
      />
      <Button
        onClick = {() => setCommentSynced(false)}
        visible = {commentSynced}
        iconID = "ChainConnected"
        text = 'Sync to playback'
        color = {commentSyncButtonsEnabled ? "create" : "inactive"}
        disabled = {!commentSyncButtonsEnabled}
      />
    </SyncButtonContainer>
    <Form onSubmit={handleSubmit(onSubmit)}>
      <TextContainer>
        {!user && <TextField defaultValue={displayName} setRef={displayNameInput} is="comment" errors={errors} hideLabel placeholder="Enter your name" label="Name" name="displayName" maxLength="20" register={register({ required: "Who are you?" })} />}
        <TextArea fullHeight setRef={textInput} is="comment" name="text" placeholder="Enter your comment" errors={errors} register={register({ required: "Don't you have anything to say?" })} />
      </TextContainer>

      <Submit type="submit">
        <SubmitIcon id="Plane" />
      </Submit>
      
      {!user && <RecaptchaDisclaimer />}
    </Form>
  </CommentPanel>
}

function TrackData({track,sortedComments,commentActive}){

  const maxComments = 4;
  const [commentMarkersExpanded,setCommentMarkersExpanded] = useState(sortedComments.length<maxComments);

  return <>
      <Scrubber track={track} style={{width:'100%',height:'128px',flexShrink:0}} marginTop={8} marginBottom={16} />
      <div style={{display:commentActive?'none':'block'}}>
        <CommentMarkers sortedComments={sortedComments} style={{width:'100%',flexShrink:0,flexGrow:0}} maxRows={commentMarkersExpanded?Infinity:maxComments} />
        {sortedComments.length > maxComments && <AsideButton onClick={() => setCommentMarkersExpanded(!commentMarkersExpanded)}>
          {commentMarkersExpanded ? <AsideButtonIcon id="SimpleArrowUp" /> : <AsideButtonIcon id="SimpleArrowDown" /> }
        </AsideButton>}
      </div>
  </>
}

function ReportForm({onClose}){
  const {user,report} = useSessionStore();  
  const challenge = useRecaptcha();
  const { register, handleSubmit, reset, errors } = useForm();
  const history = useHistory();

	const onSubmit = async (data) => {
    const recaptchaToken = user ? null : await challenge();
    const location = history.location.pathname;
    data = {...data, location, recaptchaToken};
    await report(data);
    reset();
  };
  
  return <StyledReportForm>
    <StyledCloseButton onClick={onClose} iconID="CloseX" color="destroy" iconOnly />
    <form onSubmit={handleSubmit(onSubmit)}>
      Why would you like to report this track?
      <TextArea name="reason" errors={errors} register={register({required:"You can not send empty reports."})}></TextArea>
      <StyledButton visible text="Report" iconID="Plane" color="destroy" type="submit" />
      {!user && <RecaptchaDisclaimer />}
    </form>
  </StyledReportForm>
}

export default function Track({match: {params: {uid}}}){
  const {load,track} = useTrackStore();
  useEffect(() => setDocumentTitle(track ? track.title : ''), [track])

  const {user} = useSessionStore();
  const deleteTrack = useDeleteTrack();
  const {setFile,togglePause,playing,setCommentStart, setCommentEnd,runtime,setCommentSynced,setCommentActive,commentActive} = usePlayerStore();
  const [loading, setLoading] = useState(false);
  const [reportVisible,setReportVisible] = useState(false)
  const history = useHistory();

  const sortedComments = useMemo(() => {
    if(!track) return [];
    return [...track.comments].sort((a, b) => {
      if ( a.startTime < b.startTime ) return -1;
      if ( a.startTime > b.startTime ) return 1;
      return 0;
    })
  }, [track])

  useEffect(async () => {
    if(!track || track.uid !== uid){
      setLoading(true);
      const foundTrack = await load(uid);
      if(foundTrack){
        setFile(foundTrack.file)
      }
      setLoading(false);
    }
  }, [uid])

  const handleCommentClick = useCallback(() => {
    if(commentActive){
      setCommentActive(false);
      setCommentSynced(false);
    } else {
      setCommentActive(true);
      if(runtime.commentStart === 0) {
        setCommentSynced(true);
        setCommentEnd();
        setCommentStart();
      }
    }
  }, [commentActive])

  const handleDeleteClick = useCallback(async () => {
    if(!window.confirm('Track and comments will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    await deleteTrack(track)
    history.push(`/profile`);
  }, [track])

  const deleteVisible = user && track && user.id === track.users_permissions_user.id;
  
  return loading ? <LoadingSpinner /> : track && track.file ? 
    <>
      <Container hasScrollContainer={!commentActive}>
        <Heading1 text={track.title} />
        <Submenu>
          <SubmenuGroup>
            <ButtonContainer onClick={togglePause}>
              <SubmenuButton text="Play" iconID="Play" color="audio" visible={!playing} />
              <SubmenuButton text="Pause" iconID="Pause" color="audio" visible={playing} isActive={playing} />
            </ButtonContainer>
          </SubmenuGroup>
          <SubmenuGroup>
            <ButtonContainer onClick={handleCommentClick} >
              <SubmenuButton text="Comment" iconID="Comment" color="create" visible={true} isActive={commentActive} />
            </ButtonContainer>
          </SubmenuGroup>
          <SubmenuGroup>
            {deleteVisible
              ? <ButtonContainer onClick={handleDeleteClick} >
                <SubmenuButton text="Delete" iconID="Trash" color="destroy" visible={deleteVisible} />
              </ButtonContainer>
              : <ButtonContainer onClick={() => setReportVisible(!reportVisible)}>
                <SubmenuButton text="Report" color="destroy" iconID="AlertTriangle" />
              </ButtonContainer>
            }
          </SubmenuGroup>
        </Submenu>
        <TrackData track={track} sortedComments={sortedComments} commentActive={commentActive} />
            
        <SplitView>
          <Meta>
            <Description>
            
            </Description>
          </Meta>
          <CommentContainer>
            <CommentForm visible={commentActive} />
            <Comments visible={!commentActive} track={track} sortedComments={sortedComments} />
          </CommentContainer>
        </SplitView>
      </Container>
      <ReportContainer>
        {reportVisible && <ReportForm onClose={() => setReportVisible(false)} />}
      </ReportContainer>
    </>
    : 'not found'
}