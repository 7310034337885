import { useEffect, useRef, useCallback } from "react"
import styled from 'styled-components'
import usePlayerStore from "../store/player/usePlayer";
import useTrackStore from "../store/track";
import { Link } from "react-router-dom";
import IconUnstyled from '../Icons';

const Icon = styled(IconUnstyled)`
    height: 30px;
`

const LinkStyled = styled(Link)`
    fill: #FFF;
`

const Container = styled.div`
    width: 100%;
    display: ${props => props.visible ? 'flex' : 'none'};
`
const Player = styled.audio`
    width: 100%;    
`

export default function GlobalPlayer({visible}){
    const {file, setDuration, init, togglePause, updateCurrentTime, setCurrentTime, updateVolume, setEnded,attached,runtime,setCommentActive} = usePlayerStore();
    const {track} = useTrackStore();
    const player = useRef()
    useEffect(() => {
        if(player.current){
            init(player.current)
        }
    })
    

    const handleKey = useCallback((event) => {
        const isSlider = document.activeElement.classList.contains('thumb');
        const isInput = ['TEXTAREA', 'INPUT', 'BUTTON'].includes(document.activeElement.tagName);
        const commentFormIsFocused = document.activeElement.dataset.is === 'comment';

        if(commentFormIsFocused ? event.key !== 'Escape' : isSlider || isInput){
            return;
        }
        
        const togglePlay = () => {
            togglePause()
        }
        const showCommentForm = (event) => {
            event.preventDefault();
            if(!runtime.commentActive){
                setCommentActive(true)
            }
        }
        const hideCommentForm = () => {
            if(document.activeElement.dataset.is === 'comment'){
                document.activeElement.blur();
            }
            if(runtime.commentActive){
                setCommentActive(false)
            }
        }
        const offsetTime = (offset) => {
            if(!runtime.duration) return;

            const t = Math.max(0, Math.min(runtime.duration, runtime.currentTime + offset));
            if(t !== runtime.currentTime){
                setCurrentTime(t)
            }
        }

        switch(event.key){
            case ' ':
                return togglePlay();
            case 'Enter':
                return showCommentForm(event);
            case 'Escape':
                return hideCommentForm();
            case 'ArrowLeft':
                return offsetTime(-1);
            case 'ArrowRight':
                return offsetTime(1);
        }
    }, [])

    useEffect(() => {
        document.addEventListener('keydown', handleKey);
        return () => {
            document.removeEventListener('keydown', handleKey);
        }
    }, [])

    return (
        <Container visible={visible}>
            <LinkStyled to={`/track/${track && track.uid}`}><Icon id="Expand" /></LinkStyled>
            <Player
                src={file && file.url} 
                ref={player}
                onLoadedMetadata={setDuration}
                onTimeUpdate={updateCurrentTime}
                onVolumeChange={updateVolume}
                controls
                onEnded={setEnded}
            />
        </Container>)
}