import React from "react";

import {HalfPageContainer, Heading1, BulletPoint, Paragraph, Ol} from "./Theme"
import { setDocumentTitle } from "./App";
import Disclaimer from "./UploadGuidelines"
import usePopupStore from "./store/popup/index"

export default function About() {
  setDocumentTitle('About track.gallery');
  const {setPopupComponent} = usePopupStore();

  return (
    <HalfPageContainer>
      <Heading1 text="What is this place?" />
      <BulletPoint icon="Plane" topic="Seriously, what is it?">
        <Paragraph>
          track.gallery is the place to effortlessly share your tracks and projects and receive feedback.
        </Paragraph>
        <Paragraph>
          That implies two main goals:
        </Paragraph>
          <Ol>
            <li>Ease of use for the creator: Minimize the effort to share a song.</li>
            <li>Ease of use for the reviewer: Minimize the effort to comment, get in touch and share their opinion with the creator.</li>
          </Ol>
        <Paragraph>
          Other functionality, for example concerning collaboration and community, is very welcome but secondary.
        </Paragraph>
      </BulletPoint>
      <BulletPoint icon="Heart" topic="You decide!">
        <Paragraph>
          We are aware that your art is something deeply personal. So you decide with whom you want to share your tracks.
        </Paragraph>
      </BulletPoint>
      <BulletPoint icon="Headphones" topic="We like to create music too.">
        <Paragraph>
          We are musicians ourselves. Maybe not on the same level as some of you folks, but still, music is something that has accompanied us all our lives.
        </Paragraph>
        <Paragraph>
          So, like all good ideas, this project is something we first created for ourselves before we decided to make it available to the public.
        </Paragraph>
      </BulletPoint>
      <BulletPoint icon="LoaderOutline" topic="We're working on improvements.">
        <Paragraph>
          Like wood whose true shape only becomes apparent when it has dried or like wine that still has to mature: at the moment everything is still very fresh and we are still looking for the optimal settings, the best layout, the most valuable functions and the most beautiful chords.
        </Paragraph>
        <Paragraph>
          Since we are users of this website ourselves, we are interested in making sure that everything is perfect.
        </Paragraph>
        <Paragraph>
          We are looking forward to every single suggestion for improvements, even if we might not be able to implement it.
        </Paragraph>
      </BulletPoint>

      
      <Heading1 text="Get in touch" />
      <BulletPoint icon="Comment" topic="Feedback">
        <Paragraph>
          You want to write suggestions on settings, layout or functions or simply share the most beautiful chord with us? Please feel free to contact us as soon as we have our email address up and running!
        </Paragraph>
      </BulletPoint>
      <BulletPoint icon="AlertTriangle" topic="Reporting user content">
        <Paragraph>
        Please understand that we cannot manually approve every single upload.
        </Paragraph>
        <Paragraph> 
          We therefore rely on every user to comply with our <a href="#" onClick={() => setPopupComponent(<Disclaimer />)}>upload guidelines</a>. If you happen to discover a track that violates these guidelines, please report it and we will take action as soon as possible.
        </Paragraph>
      </BulletPoint>      
    </HalfPageContainer>
  );
}