import React, {useState} from "react";
import styled, { ThemeProvider, css } from 'styled-components'
import IconUnstyled from "./Icons.js"
import {PageTheme, mediaMax} from "./Theme.js"

const Label = styled.label`
    display: block;
    margin-top: 33px;
    font-size: 13px;
    width: 100%;  
`
const DropZoneButton = styled.button`
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    cursor: pointer;
    
    font-family: inherit;
    font-variation-settings: inherit;
    
    background-color: #FFFFFF0F;
    border: 4px dashed ${props => props.theme.foregroundColor};
    color: ${props => props.theme.foregroundColor};
    fill: ${props => props.theme.foregroundColor};
    ${props => props.fileDragActive ? `background-color: #FFFFFF1C !important` : `none`};

    &:hover {
        background-color: #FFFFFF1C;
    }
`

const InputFile = styled.input`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    font-size: 0px;
    cursor: pointer;
`

const DropZoneContainer = styled.div`
    position: relative;
    display: block;

    &:hover ${DropZoneButton}{
        background-color: #FFFFFF1C;
    }
`

const InputText = styled.input`
    width: 100% !important;
    padding-bottom: 0px;
    outline: 0;

    border-bottom: 1px dotted #FFFFFF4F;
    border-top: none;
    border-left: none;
    border-right: none;

    color: #fff;
    background-color: #0000;
    
    font-family: inherit;
    font-variation-settings: inherit;
    font-weight: bold;
    font-size: 26px;
    
    filter: none;
    
    &:focus{
        border-bottom: 1px solid #FFFFFF4F;
        outline-width: 0;
    }
`


const InputTextArea = styled.textarea`
    width: 100% !important;
    ${props => props.fullHeight && 'height: 100%;'}
    padding-bottom: 0px;
    outline: 0;

    border-bottom: 1px dotted #FFFFFF4F;
    border-top: none;
    border-left: none;
    border-right: none;

    color: #fff;
    background-color: #0000;
    
    font-family: inherit;
    font-variation-settings: inherit;
    font-weight: bold;
    font-size: 20px;
    
    filter: none;
    
    &:focus{
        border-bottom: 1px solid #FFFFFF4F;
        outline-width: 0;
    }
`

const Icon = styled(IconUnstyled)`
    display: block;
    height: ${ PageTheme.properties.size5 };
	${mediaMax('small', css`
        height: 24px;
	`)}
`

const Span = styled.span`
    display: block;
    margin-left: 4px;
    font-size: 16px;
	${mediaMax('small', css`
        font-size: 12px;
	`)}
`


export const SubmenuButtonStyled = styled.button`
flex-direction: row;
    height: ${ PageTheme.properties.size6 };
    outline: 0;

    border: none;
    border-radius: ${ PageTheme.properties.ButtonBorderRadius };
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    font-family: inherit;
    font-variation-settings: 'wght' 450;

    color: ${props => props.theme.foregroundColor};
    fill: ${props => props.theme.foregroundColor};
    display: ${props => props.visible?'flex':'none'};
    align-items: center;
    padding: 0 8px;
    justify-content: center;

    background-color: ${props => props.isActive?'#ccccff22':'transparent'};

	${mediaMax('small', css`
        padding: 0 4px;
        height: ${ PageTheme.properties.size5 };
    `)}

    &:hover {
        background-color: ${props => props.isActive?'#ccccff44':PageTheme.colors.ButtonBackgroundTransparentHover};
    }
`

const ButtonStyled = styled.button`
    outline: 0;

    border: none;
    border-radius: ${ PageTheme.properties.ButtonBorderRadius };
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    font-family: inherit;
    font-variation-settings: 'wght' 500;

    color: ${props => props.theme.foregroundColor};
    fill: ${props => props.theme.foregroundColor};
    background-color: #ffffff11;
    display: ${props => props.visible === undefined ||  props.visible ? 'flex' : 'none'};
    flex-direction: row;
    align-items: center;
    padding: 0 8px;

    &:hover {
        background-color: ${PageTheme.colors.ButtonBackgroundTransparentHover};
    }
`

const RelativeContainer = styled.div`
    position: relative;
    ${props => props.fullHeight && 'height: 100%;'}
`

const ErrorMessage = styled.div`
    position: absolute;
    top: 5px;
    right: 0;
    color: ${ PageTheme.colors.destroy };
    background-color: #0004;
    font-size: 11px;
    color: #FFF;
    border-radius: 73px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 10px;
    padding-right: 10px;
    animation: highlightMessage 5s;

    @keyframes highlightMessage {
        from {background-color: #d7000080;}
        to {background-color: #0004;}
    }
`

export const SubmenuGroup = styled.div`
	margin-left: ${ PageTheme.properties.size3 };
	margin-right: ${ PageTheme.properties.size3 };
    display: inline-flex;
    flex-direction: row;
    background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
    border-radius: ${ PageTheme.properties.ButtonBorderRadius };
	${mediaMax('small', css`
        margin-right: ${ PageTheme.properties.size0 };
        flex-direction: column;
	`)}
`

export const Submenu = styled.div`
	margin-top: ${ PageTheme.properties.size4 };
	margin-bottom: ${ PageTheme.properties.size3 };

    ${SubmenuGroup}:first-of-type{
        margin-left: 0px;
    }

    ${SubmenuGroup}:last-of-type{
        margin-right: 0px;
    }
`

const CheckboxContainer = styled.div`
    background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
    display: flex;
    height: ${ PageTheme.properties.size6 };
    flex-direction: row;
    border-radius: ${ PageTheme.properties.ButtonBorderRadius };

    &:hover
    {
        background-color: ${ PageTheme.colors.ButtonBackgroundTransparentHover };
    }
`

const ExternalLinkContainer = styled.div`
    background-color: ${ PageTheme.colors.ButtonBackgroundTransparent };
    height: ${ PageTheme.properties.size6 };
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${ PageTheme.properties.size6 };
    fill: #FFF;
    cursor: pointer;
    
    &:hover
    {
        background-color: ${ PageTheme.colors.ButtonBackgroundTransparentHover };
    }
`

const CheckboxDescription = styled.label`
    flex: 1;
    display: flex;
    align-items: center;
    user-select: none;
`
const CheckboxIconContainer = styled.label`
    width: ${ PageTheme.properties.size6 };
    display: flex;
    align-items: center;
    justify-content: center;
    fill: #FFF;
`
const InputCheckbox = styled.input`
    display: none;
`

const showErrors = (errors, name) => {
    if (errors && name in errors) {
        return <ErrorMessage>{ errors[name].message } </ErrorMessage>
    }
    else
    {
        return false
    }
};


export function TextArea({register, type, name, placeholder, disabled, is, setRef, errors, fullHeight}) {
    return (<RelativeContainer fullHeight={fullHeight}>
            { showErrors(errors, name) }
            <InputTextArea
                placeholder = {placeholder}
                type = {type}
                name = {name}
                id = {name}
                ref = {el => { 
                    register(el);
                    if(setRef){
                        setRef.current = el;
                    }
                }}
                disabled = {disabled}
                data-is = {is}
                fullHeight = {fullHeight}
            />
        </RelativeContainer>)
}

export function Checkbox({register, label, text, name, placeholder, required, disabled, hideLabel, is, setRef, errors, additionalFunction}) {
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    return (
        <RelativeContainer>
            {hideLabel ? null : <Label htmlFor={name}>{label}</Label>}
            <RelativeContainer>
                { showErrors(errors, name) }
            </RelativeContainer>
            <CheckboxContainer>
                <CheckboxIconContainer>
                    { checkboxChecked ? <Icon id="CheckboxChecked" /> : <Icon id="CheckboxUnchecked" /> }
                    <InputCheckbox
                        placeholder = {placeholder}
                        type = "checkbox"
                        name = {name}
                        id = {name}
                        required = {required}
                        ref = {el => { 
                            register(el);
                            if(setRef){
                                setRef.current = el;
                            }
                        }}
                        disabled = {disabled}
                        data-is = {is}
                        onChange={() => setCheckboxChecked(!checkboxChecked)}
                    />
                </CheckboxIconContainer>
                <CheckboxDescription htmlFor={name}>
                    {text}
                </CheckboxDescription>
                {additionalFunction ? <ExternalLinkContainer onClick={additionalFunction} ><Icon id="ExternalLink" /></ExternalLinkContainer> : ''}
            </CheckboxContainer>
        </RelativeContainer>
    )
}

export function TextField({register, label, type, name, placeholder, required, disabled, hideLabel, is, setRef, defaultValue, errors}) {
    return (
        <RelativeContainer>
            {hideLabel ? null : <Label htmlFor={name}>{label}</Label>}
            <RelativeContainer>
                { showErrors(errors, name) }
            </RelativeContainer>
            <InputText
                defaultValue={defaultValue}
                placeholder = {placeholder}
                type = {type}
                name = {name}
                id = {name}
                required = {required}
                ref = {el => { 
                    register(el);
                    if(setRef){
                        setRef.current = el;
                    }
                }}
                disabled = {disabled}
                data-is = {is}
            />
        </RelativeContainer>
    )
}

export function DropZone({register, label, name, text, color, disabled, accept, errors}) {
    var theme = {foregroundColor: PageTheme.colors[color]};
    const [buttonText, setButtonText] = useState(text);
    const [fileDragActive, setFileDragActive] = useState(false);
    
    const handleChange = event => {
        var name = event.target.files[0].name;
        setButtonText(name);
    };

    return (
        <div>
            <Label htmlFor={name}>{label}</Label>
            <RelativeContainer>
                { showErrors(errors, name) }
            </RelativeContainer>
            <DropZoneContainer>
                <ThemeProvider theme={theme}>
                    <DropZoneButton text={buttonText} fileDragActive={fileDragActive}>
                        <Icon id="Download" />
                        <Span>
                            {buttonText}
                        </Span>
                    </DropZoneButton>
                </ThemeProvider>

                <InputFile
                    name = {name}
                    id = {name}
                    type = "file"
                    ref = {register}
                    onChange = {handleChange}
                    onDragEnter = {() => setFileDragActive(true)}
                    onDragLeave = {() => setFileDragActive(false)}
                    onDrop = {() => setFileDragActive(false)}
                    disabled = {disabled}
                    accept = {accept}
                /> 
            </DropZoneContainer>
        </div>
    )
}

export function Button({visible=true, text, iconID, type, color, className, onClick, disabled, iconOnly}){
    var theme = {foregroundColor: PageTheme.colors[color]};
    return (
        <ThemeProvider theme={theme}>
            <ButtonStyled
                type = {type}
                className = {className}
                disabled = {disabled}
                onClick = {onClick}
                iconOnly = {iconOnly}
                visible = {visible}
            >
                <Icon id = {iconID} />
                {iconOnly ? '' :<Span>
                     {text}
                </Span>}
            </ButtonStyled>
        </ThemeProvider>
    )
}

export function SubmenuButton({text, iconID, type, color, className, onClick, disabled, visible,isActive}){
    var theme = {foregroundColor: PageTheme.colors[color]};
    if (visible === undefined)
    {
        visible = true;
    }
    return (
        <ThemeProvider theme={theme}>
            <SubmenuButtonStyled
                type = {type}
                className = {className}
                disabled = {disabled}
                onClick = {onClick}
                visible = {visible}
                isActive={isActive}
            >
                <Icon id = {iconID} />
                <Span>{text}</Span>
            </SubmenuButtonStyled>
        </ThemeProvider>
    )
}