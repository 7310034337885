import create from 'zustand'

const usePlayerStore = create((set, get) => {
    const runtime = { 
        currentTime: 0,
        duration:0,
        commentStart: 0,
        commentEnd: 0,
        commentActive: false,
        activeCommentIndex: -1,
        cursorPosition: -1,
        requestedScrollToComment: false,
        dirty: false,
        volume: 1,
        playing: false,
        lastScrolledToComment: null,
        lastScrolledToCommentTop: 0,
        lastScrolledToCommentParentHeight: 0,
    };
    let player = null;

    const checkSwap = function(){
        if(runtime.commentStart > runtime.commentEnd){
            const swap = runtime.commentStart
            runtime.commentStart = runtime.commentEnd;
            runtime.commentEnd = swap;
        }
    }

    return ({
        currentTime: 0,
        duration: 0,
        file: null,
        playing: false,
        player: null,
        initialized: null,
        runtime: runtime,
        attached: false,
        commentActive: false,
        commentSynced:false,

        init(p){
            if(player) return;
            player = p;
        },

        setCurrentTime(time){
            runtime.currentTime = time;
            player.currentTime = time;
        },

        setVolume(volume){
            runtime.volume = volume;
            player.volume = volume;
        },

        updateCurrentTime(event){
            runtime.currentTime = event.target.currentTime;
        },

        updateVolume(event){
            runtime.volume = event.target.volume;
        },

        setDuration(event){
            runtime.duration = event.target.duration;
            set({duration: event.target.duration});
        },

        setFile(file){
            set({file, playing: false});
        },

        setCommentStart(time){
            if(time === undefined){
                runtime.commentStart = runtime.currentTime / runtime.duration;
            } else {
                runtime.commentStart = time;
            }
            checkSwap();
            runtime.dirty = true;
        },

        setCommentEnd(time){
            if(time === undefined){
                runtime.commentEnd = runtime.currentTime / runtime.duration;
            } else {
                runtime.commentEnd = time;
            }
            checkSwap();
            runtime.dirty = true;
        },

        setCommentSynced(v){
            set({commentSynced: v});
        },
        
        setCommentActive(v){
            runtime.commentActive = v;
            set({commentActive: v});
            runtime.dirty = true;
        },
        
        setActiveCommentIndex(index){
            runtime.activeCommentIndex = index;
            runtime.dirty = true;
        },
        
        setCursorPosition(v){
            runtime.cursorPosition = v;
        },

        resetActiveCommentIndex(){
            runtime.activeCommentIndex = -1;
            runtime.dirty = true;
        },

        setRequestedScrollToComment(bool){
            runtime.requestedScrollToComment = bool;
        },

        resetDirty(){
            runtime.dirty = false;
        },

        setEnded(){
            set({playing: false});
        },

        togglePause(){
            const nowPlaying = !get().playing;
            if(nowPlaying){
                player.play();
            } else {
                player.pause();
            }
            runtime.playing = nowPlaying;
            set({playing: nowPlaying})
        },

        attach(){
            set(state => ({attached: state.attached + 1}))
        },

        detach(){
            set(state => ({attached: state.attached - 1}))
        },

        setLastScrolledToComment(comment, scrollContainer){
            runtime.lastScrolledToComment = comment;
            runtime.lastScrolledToCommentTop = comment.getBoundingClientRect().top - scrollContainer.getBoundingClientRect().top + scrollContainer.scrollTop;
            runtime.lastScrolledToCommentParentHeight = scrollContainer.offsetHeight - comment.offsetHeight;
        }
    })
})

export default usePlayerStore