import create from 'zustand'
import { upload, load, fetchMine, comment, deleteTrack, deleteComment } from './api'
import { apiBaseUrl } from '../../config/environment'

function prefixUrls(prefix, obj){
    if(!obj) return;
    
    if(typeof obj === 'object'){
        if(typeof obj.url === 'string' && obj.url[0] === '/'){
            obj.url = `${prefix}${obj.url}`
        }
        Object.values(obj).forEach(prop => prefixUrls(prefix, prop))
    }
}

const useTrackStore = create((set, get) => ({
    track: null,
    activeCommentIndex: -1,
    tracks: [],

    // with jwt

    upload: async (jwt, data) => {
        const { file, title } = data;
        const { track, ok, error } = await upload(jwt, {file}, title);
        if(ok){
            prefixUrls(apiBaseUrl.slice(0, -1), track);
            return {ok, track};
        } 
        return {error};
    },

    fetchMine: async (jwt) => {
        const { tracks } = await fetchMine(jwt);
        prefixUrls(apiBaseUrl.slice(0, -1), tracks);
        set({ tracks })
        return tracks;
    },

    comment: async (jwt, authenticated, data) => {
        const { track } = await comment(jwt, authenticated, data);
        prefixUrls(apiBaseUrl.slice(0, -1), track);
        set({ track, tracks: get().tracks.map(t => t.uid === track.uid ? track : t) })
        return track;
    },

    deleteTrack: async (jwt, data) => {
        await deleteTrack(jwt, data);
        return await get().fetchMine(jwt);
    },

    deleteComment: async (jwt, trackUid, comment) => {
        const { track } = await deleteComment(jwt, trackUid, comment);
        set({ track })
        return track;
    },

    // public

    load: async (uid) => {
        const { track } = await load(uid);
        prefixUrls(apiBaseUrl.slice(0, -1), track);
        set({ track })
        return track;
    },

    setActiveComment: async (event) => {
        set({ activeCommentIndex: Math.round(event.target.dataset.index) })
    },

    resetActiveComment: async (event) => {
        set({ activeCommentIndex: -1 })
    },

}))

export default useTrackStore