import styled, {css} from 'styled-components'
import IconUnstyled from "./Icons"

export const PageTheme = {
    colors: {
        ButtonBackground: '#FFF',
		ButtonBackgroundTransparent: '#FFFFFF12',
		ButtonBackgroundTransparentHover: '#FFFFFF1C',
        create: '#128e37',
        destroy: '#ae0e0e',
        audio: '#dd9914',
		inactive: '#ffffff33'
    },

    properties: {
        ButtonBorderRadius: '4px',
		size0: '0px',
		size1: '2px',
		size2: '4px',
		size3: '8px',
		size4: '16px',
		size5: '32px',
		size6: '64px',
		size7: '128px',
	},
	
	breakpoints: {
		small: { min: 0, max: 767 },
		large: { min: 768, max: 999999 }
	}
}

export const mediaMin = (breakpoint, style) => css`
	@media (min-width: ${PageTheme.breakpoints[breakpoint].min}px) {
		${style}
	}
`

export const mediaMax = (breakpoint, style) => css`
	@media (max-width: ${PageTheme.breakpoints[breakpoint].max}px) {
		${style}
	}
`

export const HalfPageContainer = styled.div`
	width: 50%;

    margin-left: auto;
	margin-right: auto;

	padding-top: ${ PageTheme.properties.size5 };
	
	${mediaMax('small', css`
		width: calc(100% - 8px);
		margin-left: 0;
	`)}
`

const H1 = styled.h1`
	font-variation-settings: 'wght' 250;
	font-size: ${ PageTheme.properties.size6 };
	line-height: ${ PageTheme.properties.size6 };

	margin-top: ${ PageTheme.properties.size0 };
	margin-bottom: 0px;
	font-weight: 250;
	display: block;
	cursor: default;
	${mediaMax('small', css`
		line-height: ${ PageTheme.properties.size5 };
		font-size: ${ PageTheme.properties.size5 };
		margin-bottom: 0;
	`)}
`

export function Heading1({text}){
    return(
        <H1>
            {text}
		</H1>
    );
}

const H2 = styled.h2`
    font-variation-settings: 'wght' 300;
    font-size: 25px;
    line-height: 40px;

    height: 40px;
    margin-top: 35px;
    margin-bottom: 0px;
    display: block;
	cursor: default;
`

export function Heading2({text}){
    return(
        <H2>
            {text}
		</H2>
    );
}

const BulletPointIcon = styled(IconUnstyled)`
  height: ${ PageTheme.properties.size5 };
  flex-shrink: 0;
  fill: ${props => props.theme == 'dark' ? '#000000af' : '#FFFFFFA3' };
  margin-left: ${ PageTheme.properties.size5 };
  margin-right: ${ PageTheme.properties.size5 };

  ${mediaMax('small', css`
	margin-left: ${ PageTheme.properties.size3 };
	margin-right: ${ PageTheme.properties.size3 };
  `)}
`

const BulletPointTopic = styled.div`
  display: block;
  font-size: ${ PageTheme.properties.size5 };
  line-height: ${ PageTheme.properties.size5 };
  margin-bottom: ${ PageTheme.properties.size3 };
  
`

export const Paragraph = styled.div`
  margin: ${ PageTheme.properties.size3 } 0px;
`

const BulletPointContainer = styled.div`
  display: flex;
  margin-top: ${ PageTheme.properties.size5 };
  margin-bottom: ${ PageTheme.properties.size5 };
`

export const Ol = styled.ol`
  margin: ${ PageTheme.properties.size3 } 0px;
`

export const BulletPoint = ({props, children, icon, topic, theme}) => <BulletPointContainer {...props}>
      <BulletPointIcon id={icon} theme={theme} />
      <div>
        <BulletPointTopic>{topic}</BulletPointTopic>
        <span>{children}</span>
      </div>
  </BulletPointContainer>