import { useEffect } from 'react';
import useSessionStore from "./store/session/index"
import usePopupStore from "./store/popup/index";
import Popup from "./Popup"
import Navigation from "./Navigation"

export const APP_NAME = 'track.gallery';

export function setDocumentTitle(title){
	document.title = title ? `${title} - ${APP_NAME}` : APP_NAME;
}

function App() {
	const reconnect = useSessionStore(state => state.reconnect);
	useEffect(() => {
		reconnect();
	}, [])

	const {popupComponent, setPopupComponent} = usePopupStore();


  return (
	<div className="App">
		<Popup visible={popupComponent}>
			{popupComponent}
		</Popup>
		<Navigation />
	</div>
  );
}

export default App;
