import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import useSessionStore from "./store/session/index"
import usePopupStore from "./store/popup/index"
import {TextField, Submenu, SubmenuGroup, SubmenuButton, Checkbox} from "./FormControls.js"
import {HalfPageContainer, Heading1} from "./Theme"
import { useHistory } from 'react-router-dom';
import { setDocumentTitle } from "./App";
import UploadGuidelines from "./UploadGuidelines"

export default function Registration() {
	useEffect(() => setDocumentTitle('Sign up'), [])

	const history = useHistory();
	const { register, handleSubmit, reset, watch, errors } = useForm({ mode: 'all' });
	const signup = useSessionStore(state => state.signup);
	const user = useSessionStore(state => state.user);
	const {setPopupComponent} = usePopupStore();
	const [error, setError] = useState(false);
	const onSubmit = async (credentials) => {
		const signUpResult = await signup(credentials);
		if(signUpResult.ok){
			history.push(`/profile`);
			reset();
		} else {
			setError(signUpResult.error)
		}
	};

  return (
	<HalfPageContainer>
		<Heading1 iconID="Person" text="Sign Up" />
		<form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				label = "Username"
				type = "text"
				name = "username"
				placeholder = "Username"
				register = {register({ required: "Username is required" })}
				errors = {errors}
			/>

			<TextField
				label = "E-Mail"
				type = "email"
				name = "email"
				placeholder = "E-Mail"
				register={register({ required: "E-Mail is required." })}
				errors = {errors}
			/>

			<TextField
				label = "Password"
				type = "password"
				name = "password"
				placeholder = "< Password >"
				register={register({ required: "Please choose a password." })}
				errors = {errors}
			/>

			<TextField
				label = "Repeat password"
				type = "password"
				name = "password2"
				placeholder = "< Password >"
				register={register({
					validate: (value) => value !== watch('password') ? 'The password fields do not match' : undefined
				  })}
				errors = {errors}
			/>

			<Checkbox
				label = "Upload guidelines"
				name = "UploadGuidelines"
				text = 'I have read the upload guidelines and accept them.'
				register = {register({ required: 'If you agree with our upload guidelines, click this Checkbox!' })}
				errors = {errors}
				additionalFunction = {() => setPopupComponent(<UploadGuidelines />)}
			/>

			{error && <div>{error}</div>}

			<Submenu>
				<SubmenuGroup>
					<SubmenuButton				text = 'Sign Up'
						iconID = 'Heart'
						type = 'submit'
						color = 'create'
					/>
				</SubmenuGroup>
			</Submenu>
		</form>
	</HalfPageContainer>
  );
}