import create from 'zustand'
import { auth, getMe, signup, report } from './api'

const useSessionStore = create((set, get) => ({
    user: null,
    unconfirmedUser: false,
    jwt: null,
    isReconnecting: true,
    displayName: '',
    autoScrollActive: true,


    setautoScrollActive: async (status) => {
        set({autoScrollActive: status});
    },

    login: async ({identifier, password}) => {
        const data = {identifier, password};
        const { ok, user, jwt } = await auth(data);
        if(ok){
            if(user && jwt){
                set({ user, jwt, unconfirmedUser: !user.confirmed });
                localStorage.setItem('jwt', jwt);
            } else {
                set({ unconfirmedUser: true });
            }
        }
        return ok;
    },

    signup: async ({email, username, password}) => {
        const data = {email, username, password};
        const { ok, user, jwt, error } = await signup(data);
        if(ok && user){
            const data = {};
            if(jwt){
                data.jwt = jwt;
                localStorage.setItem('jwt', jwt);
            }
            if(user && user.confirmed) data.user = user;
            else data.unconfirmedUser = true;
            set(data);
        }
        return {ok, error};
    },

    logout: () => {
        localStorage.removeItem('jwt');
        set({ jwt: null, user: null });
    },

    reconnect: async ({dontSetIsReconnecting=false}={}) => {
        if(!dontSetIsReconnecting){
            set({ isReconnecting: true });
        }

        const jwt = localStorage.getItem('jwt');
        if(jwt){
            const { ok, user, error } = await getMe(jwt);
            if(!ok){
                if(error === 'Unauthorized'){
                    set({ unconfirmedUser: true, isReconnecting: false });
                } else {
                    set({ isReconnecting: false });
                }
            } else {
                set({ user, jwt, unconfirmedUser: false, isReconnecting: false });
            }
        } else {
            const displayName = localStorage.getItem('displayName') || '';
            set({ isReconnecting: false, displayName });
        }
    },

    report: async (data) => {
        const href = window.location.href;
        data = {...data, href}
        const {jwt, user}= get();
        const authenticated = !!user;
        const { ok } = await report(jwt, authenticated, data);
        return ok;
    },

    saveDisplayName: (name) => {
        set({displayName: name});
        localStorage.setItem('displayName', name)
    }

}))

export default useSessionStore