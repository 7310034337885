import { apiBaseUrl } from "../../config/environment";

export async function auth(data){
    const url = `${apiBaseUrl}auth/local`;
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(response.status === 400){
        const { message } = await response.json();
        const {id} = message[0].messages[0];
        if (id === 'Auth.form.error.confirmed'){
            return { ok: true };
        }
    }

    if(!response.ok){
        return { ok: false };
    }

    const { user, jwt } = await response.json();
    return { ok: true, user, jwt };
}

export async function signup(data){
    const url = `${apiBaseUrl}auth/local/register`;
    const response = await fetch(url, {
        method: 'POST', 
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(!response.ok){
        const {message} = await response.json();

        return { ok: false, error: message[0].messages[0].message };
    }

    const { user, jwt } = await response.json();
    return { ok: true, user, jwt };
}

export async function getMe(jwt){
    const url = `${apiBaseUrl}users/me`;
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${jwt}`
        },
    });

    if(!response.ok){
        const { error } = await response.json();
        return { ok: false, error };
    }

    const user = await response.json();
    return { ok: true, user };
}

export async function report(jwt, authenticated, data){
    const url = `${apiBaseUrl}reports`;

    const authorizationHeader = authenticated ? {
        'Authorization': `Bearer ${jwt}`
    } : {};
    const response = await fetch(url, {
        method: 'POST',
        headers: {  
            ...authorizationHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    return response.ok;
}