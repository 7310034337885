import React from "react";
import styled from 'styled-components'
import { PageTheme } from "./Theme";
import IconUnstyled from './Icons'

const DeleteIcon = styled(IconUnstyled)`
  height: 17px;
  opacity: 0.8;
`

const StyledDeleteButton = styled.button`
    position: absolute;
    right: 0;
    border: 0;
    outline: 0;

    cursor: pointer;

    background-color: transparent;
    border-top-right-radius:  ${ PageTheme.properties.ButtonBorderRadius };
    fill: #FFF;

    &:hover{
        background-color: ${ PageTheme.colors.ButtonBackground };
        fill: ${ PageTheme.colors.destroy };
    }
`

export default function DeleteButton({onClick}){
    return <StyledDeleteButton onClick={onClick}><DeleteIcon id="Trash" /></StyledDeleteButton>
}