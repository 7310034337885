import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {useTrackUpload} from "./hooks/useAuthenticatedTrack"
import { useHistory } from 'react-router-dom';
import {TextField, DropZone, Submenu, SubmenuGroup, SubmenuButton} from "./FormControls"
import {HalfPageContainer, Heading1} from "./Theme"
import LoadingSpinner, { DarkeningSpinner } from "./LoadingSpinner";
import { setDocumentTitle } from "./App";
import UploadGuidelines from "./UploadGuidelines";
import usePopupStore from "./store/popup/index"

export default function Upload(){
	useEffect(() => setDocumentTitle('Upload'), []);

	const { register, handleSubmit, setError, errors } = useForm();
	const upload = useTrackUpload();
	const history = useHistory();
	const {setPopupComponent} = usePopupStore();
	const [uploading, setUploading] = useState(false);
	const [uploadErrors, setUploadErrors] = useState({});
	const onSubmit = async (data) => {
		setUploading(true);
		const {ok,track,error} = await upload(data);
		setUploading(false);
		if(ok){
			history.push(`/track/${track.uid}`);
			setUploadErrors({});
		} else {
			setUploadErrors(error);
			setError("file", {type: "manual", message: error.track});
		}
    };

	return <HalfPageContainer>
	{uploading&&<DarkeningSpinner>
		<LoadingSpinner />
	</DarkeningSpinner>}
		<Heading1 text="Upload" iconID="CloudUpload" />
		<form onSubmit={handleSubmit(onSubmit)}>
			<TextField
				label = "Title"
				type = "text"
				name = "title"
				placeholder = "Name your Upload"
				register = {register({ required: 'Please name your upload!' })}
				disabled = {uploading}
				errors = {errors}
			/>

			<DropZone
				label = "File"
				text = "Drop your track here"
				name = "file"
				disabled = {uploading}
				color = 'audio'
				register = {register({ required: 'Choose the track you want to upload!' })}
				errors = {errors}
				accept = "audio/mpeg,.mp3"
			/>

			Please remember our <a href="#" onClick={() => setPopupComponent(<UploadGuidelines />)}>upload guidelines</a>.

			<Submenu>
				<SubmenuGroup>
					<SubmenuButton
						text = 'Upload'
						iconID = 'CloudUpload'
						type = 'submit'
						color = 'create'
						disabled = {uploading}
					/>
				</SubmenuGroup>
			</Submenu>
		</form>
	</HalfPageContainer>
}