import React from "react";
import styled, {keyframes} from 'styled-components';
import IconUnstyled from './Icons'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const StyledSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    width: 33%;
    max-width: 256px;
    fill: #ffffff44;
    animation: ${rotate} 2s infinite ease-in-out;
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  background: #00000066;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export default function LoadingSpinner(){
  return <StyledSpinner>
    <IconUnstyled id="LoaderOutline" />
  </StyledSpinner>
}

export function DarkeningSpinner({children}){
  return <SpinnerContainer>
    {children}
  </SpinnerContainer>
}