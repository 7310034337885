import { apiBaseUrl } from "../../config/environment";

export async function upload(jwt, files, title){
    const url = `${apiBaseUrl}tracks`;
    
    const data = {title};
    const formData = new FormData()
    formData.append('files.file', files.file[0])
    formData.append('data', JSON.stringify(data))

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${jwt}`,
        },
        body: formData,
    });

    if(response.status === 400){
        const { message } = await response.json();
        return { ok: false, error: message[0].messages[0] }
    }

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function load(uid){
    const url = `${apiBaseUrl}tracks/${uid}`;
    
    const response = await fetch(url, {
        method: 'GET'
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}


export async function fetchMine(jwt){
    const url = `${apiBaseUrl}tracks/mine`;
    
    const response = await fetch(url, {
        method: 'GET',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
        },
    });

    if(!response.ok){
        return { ok: false };
    }

    const tracks = await response.json();
    return { ok: true, tracks };
}


export async function comment(jwt, authenticated, data){

    const uid = data.track;
    delete data.track;
    
    const url = `${apiBaseUrl}tracks/${uid}/comment`;

    const authorizationHeader = authenticated ? {
        'Authorization': `Bearer ${jwt}`
    } : {};
    const response = await fetch(url, {
        method: 'POST',
        headers: {  
            ...authorizationHeader,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}

export async function deleteTrack(jwt, track){
    const url = `${apiBaseUrl}tracks/${track.uid}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
        },
    });

    if(!response.ok){
        return { ok: false };
    }

    return { ok: true };
}

export async function deleteComment(jwt, trackUid, comment){
    const url = `${apiBaseUrl}tracks/${trackUid}/comment/${comment.id}`;

    const response = await fetch(url, {
        method: 'DELETE',
        headers: {  
            'Authorization': `Bearer ${jwt}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ commentId: comment.id })
    });

    if(!response.ok){
        return { ok: false };
    }

    const track = await response.json();
    return { ok: true, track };
}