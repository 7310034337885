import React, { useEffect, useCallback } from "react";
import useTrackStore from "./store/track/index"
import { useFetchMyTracks, useDeleteTrack } from "./hooks/useAuthenticatedTrack";
import styled from 'styled-components'
import { Link } from "react-router-dom";
import {Button} from './FormControls' 
import {PageTheme} from './Theme'
import DeleteButton from "./DeleteButton";

const ButtonStyled = styled(Button)`
  height: 100% !important;
  float: left;
  width: 100%;
  margin-top: 0px !important;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF12;
  &:hover{
    background-color: #FFFFFF1C;
  }
`

const StyledLink = styled(Link)`
  color: #FFF;
  text-decoration: none;
`

const StyledTrack = styled.div`
  height: ${PageTheme.properties.size6};
  margin-top: ${ PageTheme.properties.size2 };
  margin-bottom: ${ PageTheme.properties.size2 };
  display: block;
  position: relative;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  opacity: 0.1;
  position: absolute;
  top: 0;
  left: 0;
`

function Track({track}){
  const deleteTrack = useDeleteTrack();

  const onDeleteClick = useCallback(async (event) => {
    event.preventDefault();

    if(!window.confirm('Track and comments will be irrecoverably lost. Are you sure you want to continue?')){
      return;
    }

    await deleteTrack(track)
  }, [track])

  if(!track) return null;

  return <StyledLink to={`track/${track.uid}`}>
    <StyledTrack>
      {track.file && track.file.formats.svgWaveform && <Img src={track.file.formats.svgWaveform.url} />}
      <ButtonStyled iconID="Headphones" text={track.title} color="audio" />
      <DeleteButton onClick={onDeleteClick} />
    </StyledTrack>
  </StyledLink>
}

export default function Tracks() {
	const tracks = useTrackStore(state => state.tracks);
  const fetchMine = useFetchMyTracks();

  useEffect(async () => {
    await fetchMine();
  }, [])

  return (
    <div>
      {tracks.map((track, i) => <Track track={track} key={i} />)}
    </div>
  );
}